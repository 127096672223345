



















import Vue from 'vue';
import LocalizationEditor from '@/components/LocalizationEditor.vue';
export default Vue.extend({
  name: 'Localizations',
  components: {
    LocalizationEditor
  }
});
